:root {
  --cus-primary-color: #172145;
  --cus-secondary-color: #842029;
}

/* .react-datepicker-wrapper {
    height: 60px;
} */

body {
  background-color: #ffffff !important;
  min-width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.react-datepicker-wrapper input {
  background: url("../images/calendar.png") #fff no-repeat;
  background-size: 16px 16px;
  background-position: 10px center;
  padding: 7px 7px 7px 35px;
  font-size: 15px;
}

.chartContainer .table thead,
.chartContainer .table tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.chartContainer .table tbody {
  display: block;
  max-height: 230px;
  overflow-y: scroll;
}

.add_widget_form .modal-header {
  padding: 10px;
  border-radius: 10px 10px 0 0;
}

.add_widget_form form {
  margin: 0;
  border-radius: 0 0 10px 10px;
}

.side_fixed_icon a:hover {
  color: #ffffff;
}

.chartContainer {
  position: relative;
  padding-top: 40px;
  /* margin-top: 40px; */
}

.chartContainer h6 {
  text-align: center;
  background: #0077fd;
  padding: 10px;
  color: #fff;
  margin: 0 0 20px 0;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
}

.full_height,
.react-card-flip {
  height: 100%;
}

.flip-card-back button:hover,
.flip-card-back button:active {
  background-color: #ffffff;
  color: #000;
}

.flip-load-container {
  min-height: 430px;
}

.spinnerCLS {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* 29-12-2022 */
.react-grid-item {
  overflow: hidden;
}

.innerdiv {
  padding: 58px 10px 10px 10px;
}

.sidebar-search {
  width: 94%;
}

/*------------------------------------ 06-01-23 ------------------------------------*/
.card_content li address {
  text-align: left;
}

.flip-card-back li span {
  min-height: inherit;
  font-size: 16px;
  font-weight: 500;
}

/*.clientReportTitle {
    border-bottom: none;
    background-color: #0077fd;
    padding: 10px 20px;
    color: #ffffff;
}*/
.clientReportTitle {
  border-bottom: none;
  background-color: #f2f2f2;
  padding: 10px 20px;
  color: #3d3c64;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.clientReportTitle h3 {
  margin: 0;
  font-size: 19px;
}

.clientpart select {
  padding: 8px 25px;
  border: none;
  background-color: #ffffff;
  color: #000;
  appearance: none;
  background-image: url(../images/dropdown.svg);
  background-repeat: no-repeat;
  background-position: right 7px center;
  background-size: 0.95em;
}

select.nbutton {
  padding: 5px 25px;
}

.react-datepicker-wrapper input,
#filterTable table select {
  border: none;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  position: initial;
}

.nav-tabs-custom-filter-new select {
  margin-bottom: 15px;
}

table select {
  border: none;
  padding: 7px;
}

.custom_table_width {
  width: 100%;
  margin: auto;
}

.client-content {
  height: calc(100% - 63px) !important;
}

.table-info th {
  font-weight: normal;
}

#filterTable table {
  text-align: center;
  vertical-align: middle;
}

.filter-container .ClientDatePicker {
  padding-top: 0 !important;
}

#filterTable .ClientDatePicker {
  padding-top: 0 !important;
}

#filterTable table th {
  background-color: #3d3c64;
  color: #fff;
  font-size: 14px;
}

.pagination-container .pagination-item.selected {
  background-color: rgb(61 60 100) !important;
  color: #fff;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.table_pagi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 13px;
}

.g_map {
  display: flex;
}

.svg_map {
  width: 50%;
  padding: 15px;
}

.svg_map svg {
  width: 100%;
  height: 100%;
  background: #eef4f6;
  box-shadow: 0 0 5px #ddd;
}

.svg_table {
  width: 50%;
  padding: 15px;
}

.boxdiv td {
  font-size: 13px;
}

.flip-card {
  margin: unset;
  /* background-image: url('../images/client-media.svg');
    background-position: bottom;
    background-repeat: no-repeat; */
}

.flip-card-inner {
  height: 350px !important;
  position: relative;
  box-shadow: unset;
}

.flip-card-inner.disabled {
  filter: blur(4px);
  opacity: 0.2;
}

.flip-card-front::after {
  position: absolute;
  content: "";
  width: 34px;
  height: 100%;
  background-color: var(--cus-primary-color);
  left: 0;
  top: 0;
}

.lowercornor,
.uppercornor {
  /* visibility: hidden; */
}

.lowercornor::after,
.lowercornor::before {
  bottom: -31px;
  left: 19px;
}

.uppercornor::after,
.uppercornor::before {
  top: -31px;
  left: 19px;
}

.lowercornor::after,
.lowercornor::before,
.uppercornor::after,
.uppercornor::before {
  position: absolute;
  content: "";
  width: 33px;
  height: 33px;
  z-index: 1;
}

.lowercornor::after,
.uppercornor::after {
  width: 35px;
  height: 33px;
  background-color: var(--cus-secondary-color);
  border-radius: 50%;
}

.lowercornor::before,
.uppercornor::before {
  width: 33px;
  height: 33px;
  background-color: var(--cus-primary-color);
}

.uppercornor::after {
  border-radius: 0% 0% 0% 50%;
}

.lowercornor::after {
  border-radius: 50% 0 0% 0%;
}

.flip-card-front,
.flip-card-back {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.flip-card-back {
  background-color: var(--cus-primary-color);
}

.flip-card-back li span {
  background: var(--cus-secondary-color);
}

.card-flip .clientReportTitle {
  padding: 15px 25px;
  background-color: #ffffff;
  border-radius: 8px;
}

.client-content .col-xxl-4 .card {
  position: sticky;
  top: 20px;
}

.comnbox {
  box-shadow: unset;
  border-radius: 8px;
}

.flip-card-front {
  flex-direction: column;
  align-items: start;
  z-index: 2;
  background-color: var(--cus-secondary-color);
}

.card_logo h1 {
  position: relative;
}

.card_logo::after,
.card_logo::before {
  position: absolute;
  content: "";
  background-color: #172145;
  top: 0;
  width: 100px;
  height: 40px;
}

.card_logo::after {
  left: -100px;
}

.card_logo::before {
  right: -420px;
  width: 420px;
}

.card_logo img {
  filter: brightness(0) invert(1);
}

.flip-card-front-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

table {
  border-radius: 8px;
  overflow: hidden;
}

.table>thead,
.table>thead:hover {
  background-color: var(--cus-primary-color);
}

table thead th {
  color: #ffffff !important;
  border: none;
  font-size: 12px;
}

table tr:hover {
  background-color: transparent;
}

table td,
table th {
  border: none;
  padding: 8px !important;
}

table th:nth-child(1),
.table thead tr:first-child,
.table>thead {
  border-top-left-radius: 8px;
}

table th:last-child,
.table thead tr:first-child,
.table>thead {
  border-top-right-radius: 8px;
}

table tr:last-child {
  border-bottom: none;
}

.table thead tr:first-child {
  border-top: none;
}

.flip-card-back button {
  background-color: transparent;
  font-size: 14px;
  border: 1px solid #fff;
}

/* .table > tbody {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
} */
.table tr:nth-child(even) {
  background-color: #f1f1f3;
}

/*------------------------------------ 06-01-23 ------------------------------------*/

@media (max-width: 525px) {
  .sidebar-search {
    width: 88%;
  }
}

.back_to_top_btn {
  text-align: end;
  cursor: pointer;
  position: fixed;
  bottom: 70px;
  right: 30px;
  font-size: 40px;
  color: #ffc106;
  margin: 0;
}

.back_to_top_btn.hide_btn {
  display: none;
}

li.nav-item.dropend:hover ul {
  display: block;
}

/*------------------------------------ 20-01-23 ( sudhit Changes ) ------------------------------------*/

#navbarSupportedContent .dropdown:hover>.dropdown-menu,
#navbarSupportedContent .dropend:hover>.dropdown-menu {
  display: block;
  margin-top: 0.125em;
  margin-left: 0.125em;
}

#navbarSupportedContent .dropend .dropdown-toggle::after {
  margin-left: 0.5em;
  font-size: 19px;
  transform: rotate(90deg);
  transition: transform 0.2s;
}

#navbarSupportedContent .dropend .dropdown-toggle:hover::after {
  transform: rotate(-90deg);
}

#navbarSupportedContent .dropend ul.dropdown-menu,
#navbarSupportedContent .dropend:hover>.dropdown-menu {
  padding: 5px;
  margin-left: 9px;
  border: none;
  box-shadow: 0 4px 6px rgb(128, 128, 128) !important;
}

#navbarSupportedContent .dropend ul.dropdown-menu li {
  border-bottom: 1px solid rgb(223 220 220);
}

#navbarSupportedContent .dropend ul.dropdown-menu li:last-child {
  border-bottom: unset;
}

#navbarSupportedContent .dropend ul.dropdown-menu li .dropdown-item {
  border-radius: 5px;
  margin: 5px 0;
}

#navbarSupportedContent .dropend ul.dropdown-menu li:first-child .dropdown-item {
  margin: 0 0 5px 0;
}

#navbarSupportedContent .dropend ul.dropdown-menu li:last-child .dropdown-item {
  margin: 5px 0 0 0;
}

#navbarSupportedContent .dropend ul.dropdown-menu li .dropdown-item:hover,
#navbarSupportedContent .dropend ul.dropdown-menu li .dropdown-item.active {
  background-color: #172145;
  color: #ffffff;
}

#navbarSupportedContent .navbar-nav li:last-child {
  display: flex;
  align-items: center;
  justify-content: left;
  align-self: unset !important;
}

#navbarSupportedContent .logout-btn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* box-shadow: 0 4px 6px rgb(255 255 255) !important; */
}

#navbarSupportedContent .logout-btn:hover {
  box-shadow: none !important;
}

#navbarSupportedContent .profile-pic {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  margin-left: 13px;
  margin-right: 13px;
}

@media (max-width: 992px) {
  #navbarSupportedContent .navbar-nav li:last-child {
    margin-left: 12px;
    margin-top: 5px;
  }

  #navbarSupportedContent .navbar-nav .dropdown-menu li:last-child {
    margin-left: unset;
  }

  #navbarSupportedContent .dropend ul.dropdown-menu,
  #navbarSupportedContent .dropend:hover>.dropdown-menu {
    box-shadow: unset !important;
  }
}

/* #filterTable table th {    min-width: 180px; } */

/* #filterTable table th:nth-child(2) { min-width: 260px; } */

.analytics {
  /* margin-bottom: 56px; */
  /* margin-top: 2%; */
  height: calc(100vh - 115px);
  overflow: auto;
  padding: 15px 15px 20px 15px;
}

/* ---------------- 23-01-23 ---------------- */
.filter-outer-container {
  background-color: #d9ebff00;
  color: #fff;
  font-size: 14px;
  padding: 0px;
  max-width: 2000px;
  flex-grow: 1;
  /* margin: auto; */
}

.table-filter-container .filter-outer-container {
  flex-direction: column;
  flex-wrap: nowrap !important;
}

.table-filter-container .filter-outer-container .filter-container {
  width: 100% !important;
  padding: 8px 0;
}

#filterTable table th {
  background-color: unset;
  color: #0077fd;
  border-color: #dfdfdf;
}

.client_table table td {
  font-size: 14px;
  padding: 8px;
  font-weight: 500;
}

.filter-outer-container .filter-container select,
.filter-outer-container .filter-container input {
  border: none;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  position: initial;
  padding: 12px;
  width: 100%;
}

.filter-outer-container .filter-container input {
  padding-left: 35px;
}

.filter-outer-container .clear-btn {
  font-weight: 500;
  /* border-bottom: 2px Solid #d9ebff; */
  transition: all 0.3s;
}

#filterTable .table thead tr:first-child {
  border-top-color: #dfdfdf;
}

/* #filterTable .table tbody tr:first-child{
    border-top: none;
}    */

.filter-outer-container .filter-container:last-child {
  margin-left: auto;
  justify-content: end;
}

.analytics .custom_table_width {
  width: 100%;
}

.filter-outer-container .filter-container {
  padding: 0px;
  width: 20%;
}

@media (min-width: 1800px) {
  .filter-outer-container .filter-container {
    width: 20%;
  }
}

@media (max-width: 992px) {
  .filter-outer-container .filter-container {
    width: 33.3333%;
  }
}

@media (max-width: 768px) {
  .filter-outer-container .filter-container {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .filter-outer-container .filter-container {
    width: 100%;
  }
}

#filterTable table tr:hover {
  background-color: transparent;
}

#filterTable .clientReportTitle {
  border-radius: 5px;
  box-shadow: unset;
}

#filterTable .filter-outer-container {
  border-radius: 5px;
  gap: 17px;
  overflow: auto;
}


#filterTable .table> :not(:first-child) {
  border-top: #dfdfdf;
}

.filter-outer-container .clear-btn:hover,
.filter-outer-container .clear-btn:active,
.filter-outer-container .clear-btn:focus {
  text-decoration: underline;
  color: #ffffff !important;
  border-color: #d9ebff !important;
  background-color: #172145;
}

/* #filterTable .table tbody tr:nth-child(odd) {
    background-color: #eff4fa;
} */
/* #filterTable table tfoot tr td:nth-child(5), #filterTable table tfoot tr td:nth-child(6),#filterTable table tfoot tr td:nth-child(7),#filterTable table tfoot tr td:nth-child(8) {
    background-color: #d9ebff;
} */

main {
  height: calc(100vh - 125px);
}

.result-bg {
  background-color: #d9ebff !important;
}

#filterTable .clientReportTitle select {
  border: none;
}

.tool_tip {
  z-index: 1;
  background-color: black !important;
}

.tool_tip_graph {
  z-index: 1;
  background-color: #000 !important;
  color: #fff !important;
  font-weight: 500;
  /* margin-top: 10px; */
  border: 1px solid #000;
}

.tool_tip_graph .react-tooltip-arrow {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}

.redio_btn {
  color: black !important;
  padding: 10px 10px 0px 5px;
  cursor: pointer;
}

/* 25-01-2023 (Sudhit Changes) */
.filter-container .css-2613qy-menu {
  z-index: 10 !important;

  background: #000;
}

.zip_input {
  margin: 10px 10px;
}

/* 26-01-2023 (Sudhit Changes) */
.filter-container .css-2b097c-container {
  color: #000;
}

.filter-radio-con {
  display: flex;
  padding: 5px;
  width: 20%;
  gap: 10px;
}

.filter-container .zip_input {
  margin: unset;
  padding-left: 7px !important;
}

.filter-radio input[type="radio"] {
  cursor: pointer;
}

.refresh_btn_div {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.refresh_btn,
.filters_btn {
  margin-left: 10px;
  padding: 10px;
  border-radius: 8px;
  background: #ffff;
}

/* ---------------- 31-01-23 ---------------- */

.navbar-light .navbar-nav .nav-link {
  text-decoration: underline 2px;
  text-decoration-color: transparent;
  text-underline-offset: 8px;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link,
.des_filter ul li a:hover {
  text-decoration-color: #ffffff;
  background-color: transparent;
  color: #ffffff;
}

/* .page-body-wrapper {

}

.main-panel {
  min-height: 100%;
} */

.changepassword-box {
  background-color: #e3e4e8;
  height: calc(100vh - 68px);
  overflow-y: auto;
}

.changepassword-box>div {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  max-width: 768px;
}

/* 
.changepassword-box {
  background: url('../images/back-cunsumer-cove.png') no-repeat;
  background-size: contain;
} */

.react-grid-item:not(.react-grid-placeholder) {
  background: transparent;
  border: none;
}

.react-card-flip {
  border: none;
  border-radius: 8px;
  box-shadow: 0 10px 20px #0000001a;
}

.chartContainer h6 {
  text-align: start;
  font-weight: 700;
  background: #ffffff;
  color: var(--cus-secondary-color);
  padding: 12px;
  font-size: 17px;
}

.navbar {
  background: var(--cus-primary-color);
  box-shadow: 0 10px 20px #0000001a;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0 5px 10px #47474747;
}

.side_fixed_icon {
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}

#navbarSupportedContent .logout-btn {
  background: #ffffff;
  color: var(--cus-secondary-color);
}

.wigetoverlay {
  background: #ffffff;
  color: var(--cus-secondary-color);
}

.wigetoverlay i {
  font-size: 14px;
  cursor: pointer;
  margin: 0 8px;
  color: var(--cus-primary-color);
}

.wigetoverlay .fa-chart-line {
  border: none;
  font-size: 19px;
}

.wigetoverlay .fa-list {
  border: none;
  font-size: 16px;
}

.chartContainer {
  padding-top: 20px;
}

.side_fixed_icon a:hover {
  background-color: var(--cus-primary-color);
}

#flyoutContainer div#tickerContainer div#ticker h1 {
  background-color: var(--cus-secondary-color);
}

#flyoutContainer div#tickerContainer div#ticker div.ticker-content ul {
  color: var(--cus-secondary-color);
}

#flyoutContainer div#tickerContainer div#ticker div.ticker-content ul li .eventName {
  color: var(--cus-primary-color);
}

/* .canvasjs-chart-container {
    height: 100%;
} */

/* loader css */

.spinnerCLS {
  width: 76px;
  height: 76px;
}

.loader-container {
  min-width: 76px;
  width: 76px;
  height: 76px;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  transition: 0.3s linear;
}

.loader-main {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: var(--cus-secondary-color) var(--cus-secondary-color) transparent;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

.loader-main:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent var(--cus-primary-color) var(--cus-primary-color);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -webkit-animation: rotationBack 0.5s linear infinite;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.table-data {
  position: relative;
  min-height: 150px;
}

#filterTable .loader-container {
  width: 100px;
  height: 100px;
}

#filterTable .loader-mini,
#filterTable .loader-mini::after,
#filterTable .loader-mini::before {
  width: 70px;
  height: 70px;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes rotationBreak {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBreak {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-mini {
  width: 55px;
  height: 55px;
  display: inline-block;
  position: relative;
}

.loader-mini::after,
.loader-mini::before {
  content: "";
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #84202885;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: animloader14 2s linear infinite;
  animation: animloader14 2s linear infinite;
}

.loader-mini::after {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  opacity: 0;
}

.buttons-container {
  background-color: unset;
}

.buttons-container * {
  transition: all 2s;
}

.button-icon {
  font-size: 16px;
}

.side_fixed_icon {
  border-radius: unset;
}

.button-btn {
  display: flex !important;
  gap: 8px;

  background: #333;
  padding: 14px 16px !important;
  justify-content: center;
  /* width: 130px; */
  width: 36px;
  overflow: hidden;
  margin: 0px 0;
  margin-left: auto;
}

.button-text {
  display: none;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1;
}

.button-btn:first-child {
  border-top-left-radius: 10px 10px;
}

.button-btn:last-child {
  border-bottom-left-radius: 10px 10px;
}

.button-btn:hover {
  width: 120px;
  border-radius: 6px 0 0 6px;
  justify-content: space-between;
  background: linear-gradient(180deg,
      var(--cus-primary-color),
      var(--cus-secondary-color)) !important;
}

.button-btn:hover .button-text {
  display: inline-block;
}

@-webkit-keyframes animloader14 {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes animloader14 {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* .canvasjs-chart-canvas {
    bottom: -30px;
} */

/* ---------------- 01-02-2023 ----------------- */

.table> :not(caption)>*>* {
  background-color: unset !important;
}

.graph-info-table,
.graph-info-table td,
.graph-info-table th,
.graph-info-table thead,
.graph-info-table tbody {
  border: none !important;
  background: unset;
  color: unset;
}

.graph-info-table {
  border-radius: 8px;
  overflow: hidden;
}

.graph-info-table tr {
  border-radius: 0px;
  overflow: hidden;
  background: unset;
  color: unset;
  border: 1px solid #d5d6da !important;
  border-top: none !important;
}

.graph-info-table thead {
  background: var(--cus-primary-color);
  color: #ffffff;
  overflow: auto;
  min-width: 100%;
}

.chartContainer .table tbody {
  display: block;
  height: calc(100% - 50px);
  overflow-y: auto;
  max-height: unset !important;
  border-bottom: 1px solid #d4d5d9 !important;
  border-radius: 9px;
}

.chartContainer .table tbody tr:last-child {
  border-bottom: none !important;
}

.chartContainer,
.graph-info-table {
  height: 100%;
}

.graph-info-table thead tr {
  border: none !important;
}

.graph-info-table tr:nth-child(even) {
  background-color: #f1f1f3;
}

.table-cu-bor {
  display: block !important;
  border-radius: 8px;
  overflow: hidden;
  background: linear-gradient(45deg,
      var(--cus-primary-color),
      var(--cus-secondary-color));
  color: #ffffff;
}

.boxdiv:hover .wigetoverlay {
  top: 4px;
}

.wigetoverlay i {
  font-size: 17px;
}

.wigetoverlay .fa-sync-alt,
.wigetoverlay .fa-pen {
  font-size: 15px;
}

.wigetoverlay .fa-trash-alt {
  color: #dc3545;
}

.styles-module_tooltip__mnnfp {
  padding: 3px 15px !important;
  z-index: 10;
}

.add_widget_form .modal-header {
  background: var(--cus-primary-color);
  padding: 15px;
}

.add_widget_form form {
  padding: 20px 15px;
}

.sidebar_closebtn__2Gh0N {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-light {
  padding: 8px 30px !important;
}

.modal-container:not(.modal-dialog) {
  cursor: url("../images/close.png"), auto;
}

.sidebar_modalBackdrop__1lygP .modal-dialog {
  cursor: default;
}

.add_widget_form select {
  height: 43px;
  padding: 5px 10px;
}

.add_widget_form form button {
  margin: unset;
}

.sidebar {
  /* box-shadow: -10px 0 96px #0000004d; */
  background: transparent;
  align-items: end;
}

.tabmenu {
  transition: all 800ms ease-in-out;
  transition-delay: 0.5s;
  width: 0%;
  padding-left: 0px;
}

.filtermenu {
  width: 100%;
  justify-content: end;
}

.sidebar.show .tabmenu {
  width: 40%;
  padding-left: 5px;
}

/* .tab-content {
    transition: width 800ms ease-in-out;
    transition-delay: 1s;
    width: 100%;
}
.sidebar.show .tab-content {
    width: 60%;
} */

.login-container {
  background: unset !important;
}

.login-fluid {
  background: url("../images/loginBg.jpg") no-repeat !important;
  background-size: cover !important;
  position: relative;
  overflow: hidden;
}

.login-fluid::before {
  position: absolute;
  content: "";
  inset: 0 0 0 0;
  background: #172145d9;
}

.login-sub-con {
  z-index: 2;
  margin: auto 20% auto auto !important;
}

.login-img {
  position: relative;
  z-index: 1;
  margin: 10% auto auto 10% !important;
}

.login_formGroup__1mCGB:first-child {
  margin-bottom: 20px !important;
}

.login-footer {
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
}

.login-sub-con button {
  padding: 10px 20px !important;
  margin-top: 10px;
}

.login-sub-con input {
  padding: 12px 20px;
  border: none;
  background: #e9eaef !important;
  border-radius: 8px !important;
  border: 2px solid #fff;
}

.login-sub-con input:focus,
.login-sub-con input:active {
  border-color: var(--cus-primary-color);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #e9eaef inset !important;
}

.login-col-2 h1 {
  position: relative;
  z-index: 1;
  letter-spacing: 3px;
  color: var(--cus-primary-color);
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-thickness: 0px;
  text-decoration-color: #ffffff;
}

.login-col-2 h1 i {
  color: var(--cus-secondary-color);
}

.login-fluid::after {
  position: absolute;
  content: "";
  width: 71%;
  height: 160%;
  top: -10%;
  right: -15%;
  bottom: 0;
  background: url("../images/login-pattern.png") no-repeat top;
  background-size: 120% 70%;
  z-index: 0;
  /* transform: rotate(354deg); */
}

.login-fluid form {
  background: transparent !important;
}

.login-sub-con label {
  color: #9f9f9f !important;
  font-size: 15px !important;
  margin-bottom: 6px;
  font-weight: 500;
}

.login-col-2 {
  position: relative;
}

.login_passwordShowbtn__33IZY p {
  top: 49% !important;
  right: 5% !important;
}

.login-footer span {
  color: #172145 !important;
}

.sidebtndiv {
  transition: width 800ms ease-in-out;
  transition-delay: 0.5s;
  width: 60%;
}

.sidebar.show .sidebtndiv {
  width: 100%;
}

.sidebtndiv,
.tabmenu {
  background: #e3e4e8 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--cus-primary-color) !important;
}

.filtermenu .nav .nav-link {
  color: #000;
  border-radius: 9px 0 0 9px;
}

.tab-content,
.innerdiv {
  background: var(--cus-primary-color);
}

.btn-outline-info {
  --bs-btn-color: #172145 !important;
  --bs-btn-border-color: #172145 !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #172145 !important;
  --bs-btn-hover-border-color: #172145 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #172145 !important;
  --bs-btn-active-border-color: #172145 !important;
  --bs-btn-disabled-color: #172145 !important;
  --bs-btn-disabled-border-color: #172145 !important;
}

.sidebar {
  overflow: hidden;
}

.sidebar.show .before-sidebar,
.filter-layout.show .before-filter {
  position: fixed;
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: url("../images/close.png"), auto;
}

.filter-layout .table-filter-container {
  background-color: #e3e4e8;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  margin-right: -320px;
  opacity: 0;
  height: 100%;
  transition: all 0.5s;
  z-index: 3;
  display: flex;
  flex-direction: column;
  padding: 40px 16px 16px;
}

.filter-layout.show .table-filter-container {
  margin-right: 0px;
  opacity: 1;
}

.add_widget_form .form-check-input {
  position: relative;
}

.add_widget_form .form-check-input:checked,
.add_widget_form .form-check-input {
  background-color: #dedfe4 !important;
  border-color: #dedfe4 !important;
}

.add_widget_form .form-check {
  min-height: 3rem;
}

.add_widget_form .form-switch .form-check-input::after {
  content: "Single";
  position: absolute;
  top: 18%;
  right: 22px;
  font-weight: 500;
  opacity: 0.5;
}

.add_widget_form .form-switch .form-check-input:checked:after {
  opacity: 1;
}

.add_widget_form .form-switch .form-check-input::before {
  content: "Multiple";
  position: absolute;
  top: 18%;
  left: 20px;
  font-weight: 500;
  opacity: 1;
}

.add_widget_form .form-switch .form-check-input:checked:before {
  opacity: 0.5;
}

.add_widget_form .form-switch .form-check-input {
  height: 42px;
  width: 12rem !important;
  cursor: pointer;
  margin-top: 0;
}

.add_widget_form .form-switch .form-check-input:checked,
.add_widget_form .form-switch .form-check-input {
  background-image: url("../images/switch-button.png") !important;
}

.sidebar .closebtn,
.sidebtndiv,
.filtermenu {
  z-index: 3;
}

#flyoutContainer {
  z-index: 3;
}

.filtermenu .nav {
  padding-top: 20px;
}

.tab-content {
  padding: 28px 15px;
  overflow-y: hidden;
}

#rotateicon {
  position: relative;
  top: unset;
  right: unset;
}

body {
  overflow-y: auto;
}

.chartContainer .table tbody::-webkit-scrollbar,
.checklist::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 4px;
}

.chartContainer .table tbody::-webkit-scrollbar-track,
.checklist::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background: #f1f1f117;
  border-radius: 10px;
}

.chartContainer .table tbody::-webkit-scrollbar-thumb,
.checklist::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: #7583bd49;
  border-radius: 10px;
  transition: all 0.5s;
}

.chartContainer .table tbody::-webkit-scrollbar-thumb:hover,
.checklist::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
  background: #7584bd;
}

.clientpart select {
  border: none;
  padding-left: 20px;
}

.clientpart .searchbox select {
  padding-left: 40px;
}

.searchbox {
  position: relative;
}

.searchbox i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  color: var(--cus-secondary-color);
}

.card_content {
  margin: auto 0;
  width: 100%;
}

.card_logo {
  margin: auto auto auto 12%;
  z-index: 2;
  position: relative;
}

.card_content,
.list-unstyled {
  position: relative;
  z-index: 1;
}

.card_content li i {
  font-size: unset;
  width: unset;
  height: unset;
  line-height: unset;
  background: unset;
  border-radius: unset;
}

.cursor-pointer {
  cursor: pointer;
}

.client-content .side {
  transition: all 0.5s;
}

.side-left {
  margin-left: -33.33333333%;
  opacity: 0;
}

.side-right {
  width: 100% !important;
}

.card_content::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 100%;
  background-color: var(--cus-primary-color);
  left: 0;
  top: 0;
  border-radius: 0 20px 20px 0;
}

.checklist {
  max-height: calc(84vh - 65px);
}

@media (max-width: 1650px) {
  .client-content .col-xxl-4 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .client-content .col-xxl-8 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .side-left {
    margin-left: -41.66666667%;
  }
}

@media (max-width: 1550px) {
  .login-fluid::after {
    width: 90%;
    right: -20%;
    background-size: 132% 70%;
  }

  .login-footer {
    left: 40%;
  }
}

@media (max-width: 1350px) {
  .flip-card-back li p {
    font-size: 13px;
    padding: 5px;
  }

  .flip-card-inner {
    height: 315px !important;
  }

  .flip-card-front::after {
    width: 19px;
  }

  .card_content {
    padding: 0;
  }

  .lowercornor::after,
  .uppercornor::after {
    height: 35px;
  }

  .uppercornor::after,
  .uppercornor::before {
    top: -33px;
    left: 4px;
  }

  .lowercornor::after,
  .lowercornor::before {
    bottom: -31px;
    left: 4px;
  }

  .card_logo::after,
  .card_logo::before {
    height: 26px;
    top: calc(50% - 15px);
  }

  .card_content::after {
    width: 26px;
  }
}

@media (max-width: 1200px) {
  .client-content .col-xxl-4 {
    width: 100%;
  }

  .client-content .col-xxl-8 {
    width: 100%;
  }

  .side-left {
    display: none;
  }
}

@media (max-width: 992px) {
  .login-fluid::after {
    display: none;
  }

  .login-sub-con {
    margin: auto !important;
  }

  .login-fluid form {
    background-color: #ffffff !important;
    margin-top: 15%;
  }

  .login-footer {
    left: 50%;
    bottom: -15%;
  }

  .login-container {
    height: 100% !important;
  }

  .login-fluid {
    height: 100vh;
  }

  .login-img {
    margin: 10% auto auto auto !important;
  }

  .login-footer span {
    color: #ffffff !important;
  }

  .login-img {
    width: 180px;
  }
}

.client-list-radio input[type="radio"] {
  visibility: hidden;
  /* 1 */
  height: 0;
  /* 2 */
  width: 0;
  /* 2 */
}

.client-list-radio label {
  display: flex;
  flex: auto;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  background-color: #ffffff;
  color: rgb(17, 17, 17);
  padding: 5px 30px;
  border-radius: 6px;
  transition: color 0.1s ease-out, background-color 0.1s ease-in;
  user-select: none;
  margin-right: 8px;
  position: relative;
  border: 2px solid transparent;
  font-weight: 500;
}

.client-list-radio label:last-of-type {
  margin-right: 0;
}

.client-list-radio input[type="radio"]:checked+label {
  background-color: #ffffff;
  color: var(--cus-secondary-color);
  border-color: var(--cus-secondary-color);
  padding: 5px 20px 5px 40px;
}

.client-list-radio input[type="radio"]:checked+label::before {
  content: "";
  display: block;
  position: absolute;
  top: 30%;
  left: 20px;
  width: 6px;
  height: 14px;
  border: solid var(--cus-secondary-color);
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  z-index: 1;
}

.client-list-radio input[type="radio"]:hover:not(:checked)+label {
  background-color: #ffffff;
  color: var(--cus-secondary-color);
  border-color: var(--cus-secondary-color);
}

.client-box {
  display: flex;
  white-space: nowrap;
}

.client-list-radio {
  overflow-x: auto;
}

.form-control {
  padding: 0.575rem 0.75rem !important;
}

.column-toggle .css-xb97g8 {
  display: none !important;
}

.column-toggle .css-12jo7m5 {
  margin-right: 4px !important;
}

.accordion__panel {
  padding: 10px 0px !important;
  animation: fadein 0.35s ease-in;
}

/* flip card Css */
.cus-flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.cus-flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.cus-flip-card.backside .cus-flip-card-inner {
  transform: rotateY(180deg);
}

.cus-flip-card.backside .cus-flip-card-front,
.cus-flip-card .cus-flip-card-back {
  visibility: hidden;
}

.cus-flip-card.backside .cus-flip-card-back {
  visibility: visible;
}

.cus-flip-card-front,
.cus-flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cus-flip-card-back {
  transform: rotateY(180deg);
}

#v-pills-tab1 .btn-outline-info {
  border: 1px solid #ffffff;
  color: #ffffff;
}

#v-pills-tab1 .btn-outline-info:hover,
#v-pills-tab1 .btn-outline-info:active {
  background-color: #ffffff;
  color: #172145;
}

.login-sub-con p {
  top: 54% !important;
  right: 10px !important;
}

/* 22-02-2023 changes */
.cus-btn {
  background-color: #3d3c64 !important;
}

#filterTable .pagination {
  justify-content: end;
}

.export-btn {
  float: right;
  margin-bottom: 10px;
}

.export-btn button {
  background: linear-gradient(60deg, var(--cus-primary-color), var(--cus-secondary-color));
  color: #ffffff;
  padding: 7px 15px;
}

.export-btn button:hover,
.export-btn button:active {
  opacity: 0.8;
  color: #ffffff;
}

.graph-info-table .accordion__item+.accordion__item {
  border-top: unset;
}

.graph-info-table .accordion__button {
  border-radius: 5px;
  padding: 12px 18px 12px 5px;
  background-color: unset;
  color: #172145;
  font-weight: 700;
  transition: all 0.3s;
  position: relative;
}

.graph-info-table .accordion__button:hover {
  background-color: unset;
}

.graph-info-table .accordion__button::before {
  position: absolute;
  right: 0;
  transform: rotate(45deg);
  top: calc(50% - 5px);
}

.graph-info-table .accordion__button[aria-expanded='true']::before,
.graph-info-table .accordion__button[aria-selected='true']::before {
  transform: rotate(225deg);
}

.graph-info-table .accordion__item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: 1px solid #1721454a !important;
  margin: 0 15px;
}

.graph-info-table .accordion__item:last-child {
  border-bottom: unset !important;
}

.graph-info-table .accordion {
  padding: .5rem 0;

}

.graph-info-table thead {
  border-radius: 8px 8px 0 0;
}

.graph-info-table tbody {
  border-radius: 0 0 8px 8px !important;
}

.graph-info-table tbody td,
.graph-info-table thead th {
  padding: 10px !important;
}

@media only screen and (min-width:992px) and (max-width:1800px) {
  .login-sub-con {
    margin: 10% 20% auto auto !important;
  }
}



/* ------------- 02-03-2023 ( react-select changes) ---------------- */

.css-9gakcf-option,
.css-9gakcf-option:active {
  background-color: #172145 !important;
}

.css-1n7v3ny-option {
  background-color: #ebebeb !important;
}

.css-yt9ioa-option input,
.css-1n7v3ny-option input,
.css-9gakcf-option input {
  margin-right: 5px;
}

#filterTable .btn-group-toggle {
  min-width: 50%;
  max-width: calc(100% - 200px);
}

#filterTable .btn-group-toggle .column-toggle {
  width: 100%;
}

@media screen and (max-width: 768px) {
  #filterTable .btn-group-toggle {
    min-width: 100%;
  }
}


.filter-radio-div {
  display: flex;
  width: 100%;

  align-items: center;
  color: black;

}

.filter-radio-div>input {
  width: 10% !important;
}

.btn-group-vertical .css-12a83d4-MultiValueRemove {
  display: none !important;
}

.chan-pass-btn {
  margin-left: 20px;
}

.validate-msg {
  color: red;
  margin-top: 4px;
}

.box-body input {

  margin-top: 10px;

}


.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}

.red {
  color: #f00;
}

.alert-msg {
  margin-left: 22px;
}


.tabs {
  display: inline-block;
  width: 100%;
}

.tab-links:after {
  display: block;
  clear: both;
  content: '';
}

.tab-links li {
  margin: 0px 5px;
  float: left;
  list-style: none;
}

.tab-links li {
  padding: 9px 15px;
  display: inline-block;
  border-radius: 3px 3px 0px 0px;
  background: lightgray;
  color: darkgray;
  text-decoration: none;
  cursor: pointer;
}


.tab-links {
  margin-bottom: 0px;
  padding: 0px;
}

.tab-links li:hover {
  background: dimgray;
}

li.active,
li.active li:hover {
  background: #172145;
  color: white;
  font-weight: bold;
}

.tab-content-campagins {
  padding: 15px;
  width: 100%;
  border-radius: 3px;
  background: #f2f2f2;
  /* margin-left: 37px; */
}



.tab {
  display: none;
}

.tab.active {
  display: block;
}



/* ---------------- 01-02-2023 ----------------- */


.comnpadding {
  padding: 15px 0;
}

.card {
  border: none;
}

.info-box-con .counter {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  align-items: center;
  position: relative;
  background: #e7e8ec;
  border-radius: 5px;
  margin-bottom: 10px;
}

.info-box-con .counter:nth-child(odd) {
  background-color: #f0e2e2;
}

/* .info-box-con .counter:nth-child(even) {
  background-color: #f0e2e2;
} */


.info-box-con .counter i {
  margin-right: 8px;
  width: 35px;
  height: 35px;
  border: 1px dashed #0000004d;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #172145;
  background: white;
  box-shadow: -2px 2px 2px #00000012;
}

.info-box-con .counter:nth-child(odd) i {
  color: #8b2022;
}

.info-box-con .counter-label {
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
}

.info-box-con .counter-number {
  background: rgba(255, 255, 255, 0);
  border-radius: 6px;
  padding: 5px;
  color: #172145;
  font-weight: 700;
  width: 110px;
  text-align: center;
  position: relative;
}

.info-box-con .counter-number::before {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  border-left: 1px dashed #0000004d;
  width: 1px;
  height: 100%;
}

.info-box-con .counter:nth-child(odd) .counter-number {
  color: #8b2022;
}

.bord-lef {
  border-left: 1px dashed #000;
}

.info-box-con .col-xxl-12:last-child {
  border-bottom: none;
}

.nav-tabs-custom input {
  border: 1px solid #ced4da;
  padding: 10px 10px 10px 38px;
  transition: all 0s;
}

.nav-tabs-custom input:disabled {
  border: none
}

.info-box-con .counter .spinnerCLS {
  left: calc(100% - 76px);
}


.btn-primary {
  background: linear-gradient(60deg,
      var(--cus-primary-color),
      var(--cus-secondary-color));
  border: 1px solid #172145 !important;
  padding: 8px 30px !important;
  border-radius: 5px;
}


.btn-warning {
  background: transparent;
  border: 1px solid #172145;
  padding: 8px 30px !important;
  border-radius: 5px;
  white-space: nowrap;
}

.btn-outline-danger {
  padding: 8px 30px !important;
  border-radius: 5px;
}

.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
  background: #172145;
  color: #ffffff;
  border-color: #172145;
}

table .btn-warning,
table .btn-primary,
table .btn-outline-danger {
  padding: 5px 10px !important;
}

table {
  vertical-align: middle !important;
}



#filterTable table td:nth-child(1),
#filterTable table td:nth-child(2),
#filterTable table td:nth-child(3),
#filterTable table td:nth-child(5),
#filterTable table td:nth-child(6),
#filterTable table th:nth-child(1),
#filterTable table th:nth-child(2),
#filterTable table th:nth-child(3),
#filterTable table td:nth-child(5),
#filterTable table th:nth-child(6) {
  /* text-align: left !important; */
}

.woocommerce .button,
.woocommerce .button:hover,
.woocommerce-page .button,
.woocommerce-page .button:hover,
.woocommerce-page .button.alt {
  border-radius: 50px !important;
  padding: 2px 28px !important;
  font-weight: 700;
}

#filterTable table th {
  white-space: nowrap;
}

.custom_table_width .btn-group {
  border: 1px solid #17214547;
  border-radius: 7px;
}

.react-bootstrap-table-pagination select {
  border: none;
}

.button-group-j {
  white-space: nowrap;
}

#filterTable table td:nth-child(3) {
  min-width: 300px;
}

ul.pagination .page-item .page-link {
  margin: 0 5px;
  border-radius: 6px;
}

ul.pagination li.active,
ul.pagination li.active li:hover {
  background: unset;
  color: white;
  font-weight: bold;
}

.modal-content {
  border-radius: 12px;
  cursor: default;
}

#filterTable table th {
  font-size: 12px;
}

.btn-group-sm>.btn,
.btn-sm {
  font-size: 12px;
}

.react-bootstrap-table-pagination {
  margin-left: 0;
  margin-right: 0;
}

.text-blute {
  color: #172145;
}

.chip {
  background: #172145 !important;
  color: #fff !important;
}

#cus-filter th {
  white-space: nowrap;
  max-width: 400px !important;
}

#cus-filter td {
  word-break: break-all;
  max-width: 400px;
}

.notes-text {
  font-size: 13px !important;
}

.camp-tab li {
  border: 1px solid #ffffff;
  background: white;
  color: #cacaca;
  font-weight: 500;
}

.camp-tab + .tab-content-campagins {
  background: #ffffff;
}

.camp-tab li:hover {
  background: white;
  color: #172145;
}

.camp-tab li.active {
  background: #ffffff;
  color: #172145;
  border: 1px solid #ced4da;
  font-weight: 500;
}

.has-validation-callback .camp-tab {
  border-bottom: 1px solid #ced4da;
}

ul.camp-tab li.active {
  position: relative;
}

ul.camp-tab li.active::after, ul.camp-tab li:hover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  bottom: -1px;
  left: 0;
}

.filter-b-j img {
  transition: none;
  filter: brightness(0) saturate(100%) invert(40%) sepia(8%) saturate(1099%) hue-rotate(191deg) brightness(103%) contrast(88%);
}
.filter-b-j.active img {
  filter: brightness(0) saturate(100%) invert(20%) sepia(16%) saturate(5949%) hue-rotate(326deg) brightness(99%) contrast(109%);
}

.filter-b-j {
  box-shadow: 1px 1px 4px #ddddddf0;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background: #f1f1f3;
  position: relative;
  overflow: hidden;
}

.filter-b-j.active::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #172145a3;
}

.filter-b-j h6 {
  font-size: 13px;
  color: #767676;
}
.filter-b-j.active h6 {
  color: #172145;
  font-weight: 600;
}

.filter-b-j .fil-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.filter-sel-j {
  min-width: 300px;
}

.svg_table .react-bootstrap-table table {
  table-layout: fixed;
  text-align: left !important;
} 

.perview_modal .modal-container > div, .perview_modal .modal-dialog {
  max-width: 900px;
}

#offerdiv {
  background: #ededed;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0px 3px 4px #615f5f94;
}
.listingimg {
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #dddddd;
}
.listingtext h4 {
  font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 5px;
}
ul.style1.ccawidgetdescul {
  padding-left: 16px;
}
.listingbutton .applyoffer {
  display: block;
  margin-top: 10px;
  padding: 8px 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #0077fd;
  text-align: center;
  border-radius: 10px;
}
.listingbutton .applyoffer:hover, .listingbutton .applyoffer:active {
  background-color: #0077fd;
}
.mt-10{
  margin-top: 10px;;
}

/* ---------------- 26-03-2024(Sudhit Changes) ---------------- */
.nav-drowp > button {
  background: transparent;
  padding: 10px !important;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 14px;
  padding: 10px 10px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  margin: 0 !important;
}

.nav-drowp .dropdown-menu {
  min-width: 220px;
}

.nav-drowp .dropdown-item {
  padding: 8px 12px ;
  font-size: 14px;
  font-weight: 500;
}

.nav-drowp .dropdown-item:hover {
  background-color: var(--cus-primary-color);
  color: #ffffff;
}