.App {
    font-family: sans-serif;
    text-align: center;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}
  
  .modalBackdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .modalContent {
    width: 60%;
    min-height: 200px;
    background-color: white;
    padding: 25px;
  }
  
  .sidebar_add_widget_form__1TGwy {
    max-width: 500px;
    width: 100%;
}

.closebtn {
  background: #fff;
  border-radius: 50%;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  cursor: pointer;
}