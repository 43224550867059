@media only screen and (min-width:1920px) {}

@media only screen and (min-width:1600px) and (max-width:1900px) {}

@media only screen and (min-width:1300px) and (max-width:1367px) {}

@media only screen and (min-width:1200px) and (max-width:1280px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 991px) {}

@media screen and (max-width: 767px) {
    #flyoutContainer div#tickerContainer div#ticker h1 {
        padding: 0 10px;
        font-size: 13px;
    }
    #flyoutContainer div#tickerContainer div#ticker div.ticker-content ul {
        font-size: 14px;
    }
    .client_table table td {
        font-size: 14px;
        padding: 4px 8px;
    }
}

@media screen and (max-width:525px) {
    .sidebar.show {
        width: 360px;
    }
    .filtermenu .nav .nav-link {
        font-size: 14px;
        padding: 10px;
    }
    .flip-card-back li {
        width: 100%;
        padding: 0 5px;
        font-size: 15px;
    }
    #rotateicon {
        display: none;
    }
    .flip-card {
        height: auto;
        margin: 20px 0;
    }
    .flip-card-front,
    .flip-card-back {
        position: initial;
    }
    .flip-card-back {
        transform: rotateY(0deg);
        margin-top: 15px;
    }
    .flip-card-back button {
        margin-top: 15px;
    }
    .flip-card-back li span {
        min-height: inherit;
    }
    .card_content li i {
        font-size: 18px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-right: 10px;
    }
    .sidebar_sidebar_add_widget_form__1TGwy__emleX {
        width: 90%;
    }
}

@media screen and (max-width:360px) {}